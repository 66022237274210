import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';

// Need to disable strict mode due to react player bug:
// https://github.com/cookpete/react-player/issues/1508
// https://github.com/cookpete/react-player/pull/1360/files

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
