export const steps = [
  {
    selector: '.tour-titleBar',
    content: (
      <div className="jw-relative">
        <p className="jw-text-sm">
          We created a document for you to get started. Let's call it "A Wrinkle
          In Time." Click where it says "Untitled" and type the title.
        </p>
      </div>
    ),
  },

  {
    selector: '.tour-formatText',
    content: (
      <div className="jw-relative">
        <h1 className="jw-mb-3 jw-text-lg jw-font-medium">Format the text</h1>
        <p className="jw-text-sm">
          Highlight "1. Mrs. Whatsit" to bring up the formatting options. Choose
          "H1."
        </p>
      </div>
    ),
  },

  {
    selector: '.tour-startWriting',
    content: (
      <div className="jw-relative">
        <h1 className="jw-mb-3 jw-text-lg jw-font-medium">Start writing</h1>
        <p className="jw-text-sm">
          Let’s write the famous first words of this classic book. Click inside
          the editor on the line after the heading you just created. Type, “It
          was a dark and stormy night.”
        </p>
      </div>
    ),
    position: 'left',
  },
  {
    selector: '.tour-promptImage',
    content: (
      <div className="jw-relative">
        <h1 className="jw-text-lg jw-mb-3 jw-font-medium">Add Image</h1>
        <p className="jw-text-sm">
          Now hit enter/return after the sentence you just wrote to create a new
          line below it. Icons will appear to the right of your new empty line.
          Simply click the first icon in the row to add a new image.
        </p>
      </div>
    ),
    position: 'left',
  },
  {
    // selector: '.tour-ImageModal',
    content: (
      <div className="jw-relative">
        <p className="jw-text-sm">
          Go ahead and search for "dark and stormy", select an image, scroll
          down to the bottom of the modal and click "save".
        </p>
      </div>
    ),
    position: 'left',
    highlightedSelectors: ['.tour-imageModal'],
    mutationObservables: ['.tour-image'],
  },

  {
    selector: '.tour-audio',
    content: (
      <div className="jw-relative">
        <h1 className="jw-text-lg jw-mb-3 jw-font-medium">
          Select a focus track
        </h1>
        <p className="jw-text-sm">
          Let's get in the zone. Click the headphone icon and choose "Peaceful
          Thunder."
        </p>
      </div>
    ),
    highlightedSelectors: ['.peaceful-thunder'],
    mutationObservables: ['#simple-menu'],
    position: 'left',
  },
  {
    selector: '.tour-moreOptions',
    content: (
      <div className="jw-relative">
        <h1 className="jw-text-lg jw-mb-3 jw-font-medium">Share your work</h1>
        <p className="jw-text-sm">
          Click here to see options for publishing, sharing, or downloading your
          doc.
        </p>
      </div>
    ),
    position: 'left',
  },
  {
    selector: '.tour-share',
    content: (
      <div className="jw-relative">
        <p className="jw-text-sm">
          To start, try clicking <span className="jw-font-medium">share</span>
        </p>
      </div>
    ),
    position: 'left',
  },
  {
    selector: '.tour-shareSwitch',
    content: (
      <div className="jw-relative">
        <h1 className="jw-text-lg jw-mb-3 jw-font-medium">Sharing is easy</h1>
        <p className="jw-text-sm">Toggle the switch to enable sharing</p>
      </div>
    ),
    position: 'left',
  },
  {
    selector: '.tour-shareClose',
    content: (
      <div className="jw-relative">
        <h1 className="jw-text-lg jw-mb-3 jw-font-medium">Click or copy</h1>
        <p className="jw-text-sm">
          From here you can directly go to the shared document or copy the link.
          For now go ahead and click 'close'
        </p>
      </div>
    ),
    position: 'right',
  },
  {
    selector: '.tour-portfolio',
    content: (
      <div className="jw-relative">
        <h1 className="jw-text-lg jw-mb-3 jw-font-medium">
          Check out your Writer's Profile
        </h1>
        <p className="jw-text-sm">
          Let's take a peek at your profile. Click here to get started.
        </p>
      </div>
    ),
    position: 'left',
  },

  {
    selector: '.tour-profile',
    content: (
      <div className="jw-relative">
        <p className="jw-text-sm">
          Now select <span className="jw-font-medium">"Your Profile"</span>
        </p>
      </div>
    ),
    position: 'left',
  },
];
