import create from 'zustand';
import { persist } from 'zustand/middleware';

const useStepsStore = create(
  persist(
    (set) => ({
      step: -1,
      incrementStep: () => set((state) => ({ step: state.step + 1 })),
      resetStep: () => set(() => ({ step: -1 })),
      setCurrentStep: (step) => set(() => ({ step })),
    }),
    {
      name: 'step',
      getStorage: () => sessionStorage,
    }
  )
);

export default useStepsStore;
