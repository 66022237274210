// https://github.com/mui/material-ui/blob/v0.x/src/styles/colors.js

export const colors = {
  red100: '#ffcdd2',
  pink200: '#f6e5ff', // Custom
  purple200: '#ce93d8',
  deepPurple200: '#b39ddb',
  indigo200: '#9fa8da',
  blue200: '#90caf9',
  blue400: '#42a5f5',
  lightBlue200: '#81d4fa',
  cyan200: '#80deea',
  teal200: '#80cbc4',
  green200: '#a5d6a7',
  lightGreen500: '#8bc34a',
  lime200: '#e6ee9c',
  yellow300: '#fff176',
  deepOrange200: '#ffab91',
  brown200: '#bcaaa4',
  blueGrey200: '#b0bec5',
};

export const issueColors = {
  rtCliche: colors['lime200'],
  rtPassive: colors['brown200'],
  rtIndefinite: colors['purple200'],
  rtProfanities: colors['teal200'],
  rtRedundant: colors['deepPurple200'],
  rtRepeated: colors['deepOrange200'],
  rtSimplify: colors['lightBlue200'],
  rtOther: colors['pink200'],
  ltWhitespace: colors['blue400'],
  ltGrammar: colors['lightGreen500'],
  ltMisspelling: colors['yellow300'],
  ltTypographical: colors['pink200'],
  wgSo: colors['blueGrey200'],
  wgThereIs: colors['blue200'],
  wgWeasel: colors['green200'],
  wgAdverb: colors['indigo200'],
  wgTooWordy: colors['cyan200'],
  wgOther: colors['pink200'],
};

export const types = {
  LANGUAGE_TOOL: 'languageTool',
  WRITE_GOOD: 'writeGood',
};

export const localStorageConstants = {
  PORTFOLIO_ID: 'writers-work-portfolio-id',
  PORTFOLIO_JWT: 'writers-work-portfolio-jwt',
  HAS_TOUR_STARTED: 'writers-work-has-tour-started',
};

export const imageConstants = {
  IMAGE: 'image',
  IMAGE_ROW_ITEM: 'imageRowItem',
  IMAGE_ROW: 'imageRow',
};
