import { lazy } from 'react';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import PageNotFound from './components/PageNotFound';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './Layout';

const EditorPortfolioTemplate = lazy(() =>
  import('./components/EditorPortfolioTemplate')
);
const Editor = lazy(() => import('./components/Editor'));
const ShareView = lazy(() => import('./components/ShareView'));

// TODO:
// - Use better logic for reading data from backend
// - handle error states

const theme = createTheme({
  palette: {
    primary: {
      main: '#1de9b6',
    },
    white: {
      main: '#ffffff',
    },
  },
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(', '),
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/share/:jwt" element={<ShareView />} />
            <Route path="/portfolio" element={<EditorPortfolioTemplate />} />
            <Route path="/404" element={<PageNotFound />} />
            <Route
              path="/"
              element={
                <Layout>
                  <Editor />
                </Layout>
              }
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
};

export default App;
