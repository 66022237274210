import React from 'react';
import { steps } from './components/steps';
import { TourProvider } from '@reactour/tour';
import '@reactour/popover/dist/index.css';
import { Button } from '@mui/material';
import shallow from 'zustand/shallow';
import { useNavigate } from 'react-router-dom';
import { localStorageConstants } from './config';
import useStepsStore from './steps.store';

const Layout = ({ children }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const {
    step: currentStep,
    incrementStep: setCurrentStep,
    resetStep,
  } = useStepsStore(
    (state) => ({
      step: state.step,
      incrementStep: state.incrementStep,
      resetStep: state.resetStep,
    }),
    shallow
  );

  const handleClickClose = () => {
    urlParams.delete('guided_tour');

    navigate(`${window.location.pathname}?${urlParams}`, { replace: true });
    resetStep();
    localStorage.removeItem(localStorageConstants.HAS_TOUR_STARTED);
  };

  const handleExitTour = () => {
    urlParams.delete('guided_tour');
    navigate(`${window.location.pathname}?${urlParams}`, {
      replace: true,
    });
    resetStep();
    localStorage.removeItem(localStorageConstants.HAS_TOUR_STARTED);

    setTimeout(() => {
      window.location.href = `${process.env.REACT_APP_MAIN_SITE_BASE_URL}/app/`;
    }, 100);
  };

  // https://codesandbox.io/s/tour-with-data-fetching-forked-ic5es?file=/src/index.js
  const oppositeSide = {
    top: 'bottom',
    bottom: 'top',
    right: 'left',
    left: 'right',
  };

  const popoverPadding = 10;

  function doArrow(position, verticalAlign, horizontalAlign) {
    if (!position || position === 'custom') {
      return {};
    }

    const width = 24;
    const height = 24;
    const color = '#485963';
    const isVertical = position === 'top' || position === 'bottom';
    const spaceFromSide = 10;

    const obj = {
      [`--rtp-arrow-${
        isVertical ? oppositeSide[horizontalAlign] : verticalAlign
      }`]: height + spaceFromSide + 'px',
      [`--rtp-arrow-${oppositeSide[position]}`]: -height + 0 + 'px',
      [`--rtp-arrow-border-${isVertical ? 'left' : 'top'}`]: `${
        width / 2
      }px solid transparent`,
      [`--rtp-arrow-border-${isVertical ? 'right' : 'bottom'}`]: `${
        width / 2
      }px solid transparent`,
      [`--rtp-arrow-border-${position}`]: `${height}px solid ${color}`,
    };
    return obj;
  }

  return (
    <TourProvider
      onClickClose={() => {
        handleClickClose();
      }}
      onClickMask={() => {}}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      steps={steps}
      showBadge={false}
      showDots={false}
      scrollSmooth
      prevButton={() => (
        <Button
          disableElevation
          className="jw-text-sm"
          onClick={handleExitTour}
        >
          Exit Tour
        </Button>
      )}
      nextButton={({
        currentStep,
        stepsLength,
        setIsOpen,
        setCurrentStep,
        steps,
      }) => {
        const last = currentStep === stepsLength - 1;
        return (
          <>
            {currentStep <= 2 && (
              <Button
                disableElevation
                className="jw-text-sm"
                variant="contained"
                onClick={() => {
                  if (last) {
                    setIsOpen(false);
                  } else {
                    setCurrentStep((s) =>
                      s === steps?.length - 1 ? 0 : s + 1
                    );
                  }
                }}
              >
                Next
              </Button>
            )}
          </>
        );
      }}
      styles={{
        popover: (base, state) => ({
          ...base,
          background: '#485963',
          color: '#ffffff',
          borderRadius: 10,
          marginTop: currentStep <= 2 ? 5 : 0,
          marginLeft: currentStep <= 2 ? 0 : -5,
          ...doArrow(
            state.position,
            state.verticalAlign,
            state.horizontalAlign
          ),
        }),
        close: (base) => ({
          ...base,
          right: 12,
          top: 12,
        }),
        maskWrapper: (base) => ({
          ...base,
          color: currentStep === 3 ? 'transparent' : null,
        }),
      }}
      padding={{ popover: popoverPadding + 5 }}
    >
      {children}
    </TourProvider>
  );
};

export default Layout;
